import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import ImgCustom from "../img-custom"
import avatarDefault from "src/assets/images/avatar-default.webp"
import { getLogoApp } from "src/utils/app-logo"
import { getUserInfo } from "src/services/user-service"
import i18n from "src/locales"
import { userType } from "src/features/auth/props"
import { signIn } from "src/features/auth"

export default function HeaderView() {
  const authStore = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()

  const goHome = () => {
    window.open(process.env.REACT_APP_HOME_DOMAIN_URL, "_self")
  }

  const getUser = () => {
    const userId = localStorage.getItem("userId")
    if(!userId) return
    getUserInfo(userId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {        
        if (res?.data?.data) {
          const langResult = res.data.data?.language
            ? res.data.data.language
            : "vn"
          i18n.changeLanguage(langResult.toLowerCase())
          const authData = {
            user: res?.data?.data as userType,
            access_token: localStorage.getItem("accessToken") || "",
          }
          dispatch(signIn(authData))
        }
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    getUser()
  },[])

  return (
    <div className="flex items-center justify-between bg-blue-600 px-3 md:px-4 py-1">
      <div className="flex items-center cursor-pointer" onClick={goHome}>
        <img className="h-26px w-[26px]" src={getLogoApp()} alt="appLogo" />
        <span className="md:block hidden ml-[12px] text-[23px] font-bold leading-[36px] text-white">
          {process.env.REACT_APP_TITLE}
        </span>
        <span className="ml-[12px] md:block hidden text-[18px] font-medium leading-[28px] text-white ">
          {"Center"}
        </span>
      </div>
      <div className="flex items-center">
        <span className="ml-3 text-[14px] font-medium leading-[20px] text-white">
          {authStore?.value?.user?.full_name}
        </span>
        <div className="ml-[12px]">
          {!authStore?.value ? (
            <img
              alt="avatarDefault"
              src={avatarDefault}
              className="h-[32px] w-[32px] rounded-full object-cover object-center"
            />
          ) : (
            <>
              <div className="h-[32px] w-[32px] rounded-full border-[2px] border-orange-500">
                <ImgCustom
                  isAvatar={true}
                  alt="userAvatar"
                  url={authStore.value.user?.avatar_thumbnail_url as string}
                  className="rounded-full object-cover object-center"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
